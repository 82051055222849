import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';
import { Button, Form, Row, Col, Select, Typography, Card, DatePicker } from 'antd';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;

const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};
const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState(data.order);
  const [total, setTotal] = useState(data.totalPrice ? data.totalPrice : 'Nema podataka');
  const [form] = Form.useForm();
  let initialValues = {
    ...formInit,
    ...data,
    dateOfPayment: moment(data.dateOfPayment, 'DD/MM/YYYY'),
    dateOfSupply: moment(data.dateOfSupply, 'DD/MM/YYYY'),
    dateOfDocument: moment(data.dateOfDocument, 'DD/MM/YYYY')
    };
  const orderStatus = ['On hold', 'Delivered', 'Canceled', 'Pending'];
  const currentuser = useContext(UserContext);
  // const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [client, fetchClient] = useAxios('', [], currentuser.data.token, 'get');
  const [dateOfPayment, setDateOfPayment] = useState(data.dateOfPayment);
  const [dateOfSupply, setDateOfSupply] = useState(data.dateOfSupply);
  const [dateOfDocument, setDateOfDocument] = useState(data.dateOfDocument);
  // const id = data._id;
  // useEffect(() => {
  //   fetchOrders(`${SERVER_URL}/orders/${id}`, []);
  // }, [fetchOrders, id]);

  const clientId = data.companyName;
  useEffect(() => {
    if (clientId) fetchClient(`${SERVER_URL}/companies/${clientId}`, {});
  }, [fetchClient, clientId]);

  useEffect(() => {
    setTotal(
      products.reduce(
        (a, b) =>
          b.discountPercentage
            ? a + b.quantity * (b.price - (b.price * +b.discountPercentage) / 100)
            : a + b.price * b.quantity,
        0,
      ),
    );
  }, [products]);

  let filteredProducts = [];

  if (allProducts && products) {
    filteredProducts = allProducts.filter((item) => products.every((cartProduct) => item._id !== cartProduct._id));
  }

  useEffect(() => {
    const getProducts = async () => {
      try {
        const call = await axios.get(`${SERVER_URL}/data`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        setAllProducts(call.data.items);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProducts();
  }, [currentuser.data.token]);

  const onFinish = async (values) => {
    onSubmit({
      ...data,
      order: products,
      client: client.data.companyName,
      address: client.data.address,
      zip: client.data.postalCode,
      city: client.data.city,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * 0.2).toFixed(2),
      status: form.getFieldValue('status'),
      dateOfPayment: values.dateOfPayment._d.toLocaleDateString('en-GB'),
      dateOfSupply: values.dateOfSupply._d.toLocaleDateString('en-GB'),
      dateOfDocument: values.dateOfDocument._d.toLocaleDateString('en-GB'),
    });
    setTimeout(() => {
      window.location.reload();
    }, 700);
  };

  const updateOrderAndcreateInvoice = async () => {
    createInvoice({
      ...data,
      order: products,
      client: client.data.companyName,
      address: client.data.address,
      zip: client.data.postalCode,
      city: client.data.city,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * 0.2).toFixed(2),
      status: form.getFieldValue('status'),
      dateOfPayment: dateOfPayment,
      dateOfSupply: dateOfSupply,
      dateOfDocument: dateOfDocument
    });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 700);
  };

  // const discount = (percent, record) => {
  //   record.discountPercentage = percent;
  //   const newProducts = products.map(x => products.find(({ id }) => id === record._id) || x);
  //   setProducts(newProducts);
  // };

  const increment = (record) => {
    record.quantity++;
    const newProducts = products?.map((x) => products?.find(({ id }) => id === record?._id) || x);
    setProducts(newProducts);
  };

  const decrement = (record) => {
    if (record.quantity !== 1) {
      record.quantity--;
      const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);

      setProducts(newProducts);
    }
    return false;
  };

  const removeProduct = (record) => {
    const newProducts = products.filter((product) => product._id !== record);
    setProducts(newProducts);
  };

  const addProduct = (record) => {
    const findProd = allProducts.find((p) => p._id === record);

    const toAdd = {
      _id: findProd._id,
      category: findProd?.category?.title?.sr,
      code: findProd.code,
      name: findProd.title?.sr,
      quantity: 1,
      price: findProd?.wholesalePrice,
    };
    const copy = [...products, toAdd];
    setProducts(copy);
  };

  const columnKeys = ['name', 'price', 'code'];
  // let newColumnKeys = [];
  // let name, price;
  // for (const columnKey of columnKeys) {
  //   if (columnKey === 'name') {
  //     name = {
  //       originalName: columnKey,
  //       alternativeName: 'Naziv'
  //     };
  //   } else if (columnKey === 'price') {
  //     price = {
  //       originalName: columnKey,
  //       alternativeName: 'Cena'
  //     };
  //   }
  // }
  // newColumnKeys.push(name, price);
  // let fullName = '';
  // if (data.user) {
  //   fullName += data.user.firstName ? data.user.firstName + ' ' : '';
  //   fullName += data.user.lastName ? data.user.lastName : '';
  // }

  // let city = '';
  // let address = '';
  // let zip = '';
  // let phone = '';

  // if (data) {
  //   if (!data.company) {
  //     if (data.user.role[0] === 'superAdmin') {
  //       city = data.user.companyAdminInfo.city;
  //       address = data.user.companyAdminInfo.address;
  //       zip = data.user.companyAdminInfo.zipCode;
  //       phone = data.user.phone[0];
  //     } else if (data.user.role[0] === 'company') {
  //       city = data?.user?.companies[0]?.mesto;
  //       address = data?.user?.companies[0]?.adresa;
  //       zip = data?.user?.companies[0]?.postanskibroj;
  //       phone = data?.user?.phone[0];
  //     }
  //   } else if (data.company) {
  //     city = data.company.mesto;
  //     address = data.company.adresa;
  //     zip = data.company.postanskibroj;
  //     phone = data?.user?.phone[0];
  //   }

  // }

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji profakture' bordered={false}>
                  <p>
                    <Text strong>Broj profakture: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Klijent: </Text>
                    {client.data.companyName}
                  </p>
                  <p>
                    <Text strong>Matični broj: </Text>
                    {client.data.matBr}
                  </p>
                  <p>
                    <Text strong>PIB: </Text>
                    {client.data.taxId}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {client.data.city}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {client.data.address}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {client.data.phone}
                  </p>
                  <p>
                    <Text strong>Poštanski kod: </Text>
                    {client.data.postalCode}
                  </p>
                  <p>
                    <Text strong>Ukupan iznos profakture: </Text>
                    {(+total).toFixed(2)}
                  </p>
                  {/* <p>
                    <Text strong>Ukupna cena sa porezom: </Text>
                    {(+total + +total * .2).toFixed(2)}
                  </p> */}
                </Card>
                <br />
                <Form.Item label='Status profakture' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {t(`${item}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'space-between'}}>
                  <Form.Item
                      label='Datum izdavanja dokumenta'
                      name='dateOfDocument'
                      className='dateOfPayment'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete datum izdavanja dokumenta!',
                        },
                      ]}
                  >
                    <DatePicker
                        placeholder='Odaberite datum'
                        format= 'DD/MM/YYYY'
                        onChange={(e) => setDateOfDocument(e._d.toLocaleDateString('en-GB'))}
                    />
                  </Form.Item>
                  
                  <Form.Item
                      label='Datum plaćanja'
                      name='dateOfPayment'
                      className='dateOfPayment'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete datum plaćanja!',
                        },
                      ]}
                  >
                    <DatePicker
                        placeholder='Odaberite datum'
                        format='DD/MM/YYYY'
                        onChange={(e) => setDateOfPayment(e._d.toLocaleDateString('en-GB'))}
                    />
                  </Form.Item>

                  <Form.Item
                      label='Datum isporuke'
                      name='dateOfSupply'
                      className='dateOfPayment'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete datum isporuke!',
                        },
                      ]}
                  >
                    <DatePicker
                        placeholder='Odaberite datum'
                        format= 'DD/MM/YYYY'
                        onChange={(e) => setDateOfSupply(e._d.toLocaleDateString('en-GB'))}
                    />
                  </Form.Item>

                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <DataTable
                  status={data.status}
                  data={products}
                  columnKeys={columnKeys}
                  title={'OrderTable'}
                  increment={increment}
                  decrement={decrement}
                  removeProduct={removeProduct}
                  // discount={discount}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '1.2rem',
                  }}
                >
                  <Select
                    disabled={data.status !== 'On hold'}
                    placeholder={t(`${'Search and add new products'}`)}
                    showSearch
                    style={{ width: '60%' }}
                    onSelect={(e) => addProduct(e)}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {filteredProducts?.map((product) => (
                      <Option value={product._id} key={product._id}>
                        {product?.title?.sr}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button className='b2b-primary' htmlType='submit' style={{ margin: '0.5rem' }}>
                Ažuriraj
              </Button>
              <Button
                className='b2b-primary'
                disabled={data.status !== 'On hold'}
                onClick={(values) => updateOrderAndcreateInvoice(values)}
                style={{ margin: '0.5rem' }}
              >
                Potvrdi Profakturu
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;

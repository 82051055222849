import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Form, Input, DatePicker } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  // 'buyerName',
  // 'userEmail',
  'user',
  'userId',
  'totalPrice',
];

const Invoices = () => {
  const currentuser = useContext(UserContext);
  const [invoices, fetchInvoices] = useAxios('', [], currentuser.data.token, 'get');
  const [invoicesPricesSum, setInvoicesPricesSum] = useState('');
  // const [createdAtDate, setCreatedAtDate] = useState(moment().format('MM/YYYY'));
  const [createdAtDate, setCreatedAtDate] = useState('');

  const history = useHistory();

  useEffect(() => {
    fetchInvoices(`${SERVER_URL}/invoices`, []);
  }, [fetchInvoices]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/invoices/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je izbrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/invoices');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    const keys = Object.keys(invoices.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  // function for date formatting in table (dd-mm-yyyy)
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  };

  let tableData = [];
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    tableData = invoices.data.items.map((item) => {
      const createdAtFormat = formatDate(item.createdAt);
      const updatedAtFormat = formatDate(item.updatedAt);
      item.dateOfPayment = item.dateOfPayment.split('/').join('-');
      return { ...item, createdAt: createdAtFormat, updatedAt: updatedAtFormat };
    });
  }

  const openPdf = async (path) => {
    try {
      const resPdF = await Axios.get(`${SERVER_URL}/invoices/pdf?path=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  // function that is called when date from datepicker changes
  let priceTotal = 0;
  const onDateChange = (date, dateString) => {
    setInvoicesPricesSum(0);
    setCreatedAtDate(dateString);
    if (invoices.data) {
      for (let i = 0; i < invoices?.data?.items?.length; i++) {
        const dateObj = new Date(invoices.data.items[i].createdAt);
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = String(dateObj.getFullYear());
        const fullDate = `${month}/${year}`;

        if (fullDate === dateString) {
          priceTotal += parseFloat(invoices.data.items[i].totalPrice);
          setInvoicesPricesSum(priceTotal.toLocaleString('sr-RS'));
        }
      }
    }
  };

  // current date is starting date to render sum of prices of invoices
  useEffect(() => {
    if (invoices.data) onDateChange(null, moment().format('MM/YYYY'));
  }, [invoices]);

  return (
    <div className='table'>
      <div style={{ display: 'flex', gap: '40px', marginLeft: '10px', marginTop: '20px' }}>
        <Form.Item label={`Ukupan iznos na datum ${createdAtDate}:`}>
          <Input disabled value={invoicesPricesSum} suffix='RSD' />
        </Form.Item>
        <Form.Item label='Datum:' valuePropName={'date'}>
          <DatePicker
            onChange={onDateChange}
            picker='month'
            style={{ height: '32px' }}
            format='MM/YYYY'
            placeholder='Izaberi datum'
            defaultValue={moment()}
          />
        </Form.Item>
      </div>
      <div style={{ textAlign: 'center' }}>
        {invoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='Računi'
            openPdf={openPdf}
          />
        )}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length === 0 && (
          <h2>NEMA PODATAKA</h2>
        )}
      </div>
    </div>
  );
};

export default Invoices;

import React, { useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Table, Popconfirm, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, EditFilled, DeleteOutlined, DeleteFilled } from '@ant-design/icons';

const ContactsCompany = (props) => {
  const currentuser = useContext(UserContext);
  const [company, fetchCompany] = useAxios('', [], currentuser.data.token, 'get');
  const { id } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompany(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } else {
      console.log('nepoznat ID');
    }
  }, [currentuser.data.token, currentuser.data.role, company, fetchCompany, id]);

  console.log('company', company);

  const columns = [
    {
      key: '1',
      title: 'Kontakt osoba',
      dataIndex: 'contactPerson',
    },
    {
      key: '2',
      title: 'Kontakt telefon',
      dataIndex: 'contactPhone'
    },
    {
      key: '3',
      title: 'Kontakt email',
      dataIndex: 'contactEmail'
    },
    {
      key: '4',
      title: 'Pozicija',
      dataIndex: 'contactPosition'
    },
    {
      key: '5',
      title: 'Opcije',
      render: (record) => {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
                <EditOutlined
                  className='icon-unlock'
                  title={`Izmena kontakta`}
                  onClick={() => onEditContact(id)}
                />
                <EditFilled
                  className='icon-lock'
                  title={`Izmena kontakta`}
                  onClick={() => onEditContact(id)}
                />
              </div>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
                <Popconfirm
                  placement='left'
                  title='Ovo će izbrisati kontakt'
                  okText='U redu'
                  cancelText='Poništi'
                  onConfirm={() => { onDeleteContact(record); }}
                  cancelButtonProps={{ className: 'b2b-secondary' }}
                  okButtonProps={{ className: 'b2b-primary' }}
                >
                  <DeleteOutlined
                    className='icon-unlock'
                    title={`Brisanje kontakta`}
                    type='delete'
                    style={{ marginLeft: '12px' }} />
                  <DeleteFilled
                    className='icon-lock'
                    title={`Brisanje kontakta`}
                    type='delete'
                    style={{ marginLeft: '12px' }} />
                </Popconfirm>
              </div>
            </div>
          </>
        );
      }
    },
  ];

  const onEditContact = (id) => {
    history.push(`/admin/edit-company/${id}`);
  };

  const onDeleteContact = async (record) => {
    console.log('delete record', record);
    try {
      await Axios.delete(`${SERVER_URL}/companies/contacts/${record.contactEmail}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Kontakt je izbrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }

  };

  let tableData = [];
  if (company.data.contactPerson && company.data.contactPerson.length > 0) {
    tableData = company.data.contactPerson.map((item) => {

      return item;
    });
  }
  console.log('tableData', tableData);
  console.log('columns', columns);
  return (
    <div className='table'>
      <div className='contacts-header'>
        <h3>Kontakt podaci kompanije {company.data.companyName}</h3>
      </div>
      <div style={{ textAlign: 'center', overflowX: 'auto' }}>

        <Table
          size='middle'
          bordered
          dataSource={tableData}
          columns={columns}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />

      </div>
    </div>

  );
};

export default ContactsCompany;
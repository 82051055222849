import React, { useContext } from 'react';
import { Form, Input, Card } from 'antd';
import { UserContext } from '../App';

// const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  // background: '#008139',
  // background: '#fff',
  background: 'linear-gradient(#534a8d, #d73d83)'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgb(255, 255, 255)'
};

const companyLogoStyle = {
  maxWidth: '250px',
  marginBottom: '50px'
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          {/* <img style={companyLogoStyle} className="login-logo" src="/logoW.png" alt="Universal Logo" /> */}
          <img style={companyLogoStyle} className="login-logo" src="./kojan_logo.png" alt="Universal Logo" />
          {/* <Meta
            title="Login"
          /> */}
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Molim Vas da unesete Vaš email!' }]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Molim Vas da unesete Vaš password!' }]}>
              <Input.Password placeholder='Password' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <button className="b2b-primary" type='primary' style={{ 'width': '100%' }} htmlType='submit'>
                Prijavi se</button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { notification, Input, Form } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'featuredProduct',
  'inactiveProduct',
  'superPrice',
  'newProduct',
  'discountWholesale',
  'discountRetail',
  'wholesalePriceWithTax',
  'supplier',
  'createdAt',
  'updatedAt',
  'retailPrice',
  'discountRetailPrice',
  'discountWholesalePrice',
  'reservedQuantity',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [importPriceSum, setImportPriceSum] = useState();
  const [wholesalePriceSum, setWholesalePriceSum] = useState();
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Proizvod je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.inStock =
          item.quantity > 0 ? (
              <span>
            <CheckCircleFilled /> Na stanju
          </span>
          ) : (
              <span style={{ color: 'red' }}>
            <CloseCircleFilled style={{ color: 'red' }} /> Nema na stanju
          </span>
          );

      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/import-data`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  let importPriceTotal = 0;
  let wholesalePriceTotal = 0;
  if (data.data) {
    for (let i = 0; i < data?.data?.items?.length; i++) {
      if (data.data.items[i].quantity > 0) {
        importPriceTotal += data.data.items[i].importPrice * data.data.items[i].quantity;
        wholesalePriceTotal += data.data.items[i].wholesalePrice * data.data.items[i].quantity;
      }
    }
  }

  useEffect(() => {
    setImportPriceSum(importPriceTotal.toLocaleString('sr-RS'));
    setWholesalePriceSum(wholesalePriceTotal.toLocaleString('sr-RS'));
  }, [importPriceTotal, wholesalePriceTotal]);

  return (
      <div className='table'>
        <div className='actions-block'>
          <Link to='/admin/new-data'>
            <button className='b2b-primary'>Dodaj proizvod</button>
          </Link>
          {/*<Button className='importbutton' disabled={!currentuser.data.role.includes('superAdmin')} style={{ marginLeft: '10px' }} icon={<UploadOutlined />} onClick={() => document.getElementById('input-file').click()} type="button">Uvoz podataka</Button>*/}
          {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
        </div>

        <div style={{ display: 'flex', gap: '40px', marginLeft: '10px' }}>
          <Form.Item label='Uvozna cena ukupno'>
            <Input disabled value={importPriceSum} />
          </Form.Item>
          <Form.Item label='Veleprodajna cena ukupno'>
            <Input disabled value={wholesalePriceSum} />
          </Form.Item>
        </div>

        <div style={{ textAlign: 'center' }}>
          {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
              <Table
                  data={tableData}
                  deleteHandler={deleteDataHandler}
                  columnKeys={columnKeys}
                  title='Data'
                  editPath='/admin/edit-data/'
              />
          )}
          {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
        </div>
      </div>
  );
};

export default Data;




// import React, { useEffect, useContext } from 'react';
// import Axios from 'axios';
// import { Link, useHistory } from 'react-router-dom';
// import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
// import { notification } from 'antd';
// import { UserContext } from '../../App';
// import useAxios from '../../hooks/useAxios';
// import Table from '../../components/tables/DataTable';
// import { SERVER_URL } from '../../config';
//
// const TABLE_COLUMN_KEYS = [
//   '_id',
//   '__v',
//   'contacted',
//   'gallery',
//   'discount',
//   'featureImage',
//   'follow',
//   'views',
//   'googleDescription',
//   'keywords',
//   'content',
//   'gallery',
//   'categoryPath',
//   'fullUrl',
//   'url',
//   'comments',
//   'attributes',
//   'featuredAd',
//   'featuredAdUntil',
//   'user',
//   'declaration',
//   'featuredProduct',
//   'inactiveProduct',
//   'superPrice',
//   'newProduct',
//   'discountWholesale',
//   'discountRetail',
//   'wholesalePriceWithTax',
//   'supplier',
//   'createdAt',
//   'updatedAt',
//   'retailPrice',
//   'discountRetailPrice',
//   'discountWholesalePrice',
//   'reservedQuantity'
// ];
//
// const Data = () => {
//   const currentuser = useContext(UserContext);
//   const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
//   const history = useHistory();
//
//   useEffect(() => {
//     fetchData(`${SERVER_URL}/data`, []);
//   }, [fetchData]);
//
//   const deleteDataHandler = async (id) => {
//     try {
//       await Axios.delete(`${SERVER_URL}/data/${id}`, {
//         withCredentials: false,
//         headers: { Authorization: `Bearer ${currentuser.data.token}` },
//       });
//       notification.success({
//         message: 'Proizvod je obrisan.',
//         placement: 'bottomRight',
//       });
//       history.push('/admin');
//       history.push('/admin/data');
//     } catch (err) {
//       notification.error({
//         message: 'Problem with delete. Please try later.',
//         placement: 'bottomRight',
//       });
//     }
//   };
//
//   let columnKeys;
//   if (data.data && data.data.items && data.data.items.length > 0) {
//     const keys = Object.keys(data.data.items[0]);
//     columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
//   }
//
//   let tableData = [];
//   if (data.data && data.data.items && data.data.items.length > 0) {
//     tableData = data.data.items.map((item) => {
//       item.createdAt = new Date(item.createdAt).toLocaleString();
//       item.updatedAt = new Date(item.updatedAt).toLocaleString();
//       item.inStock = item.quantity > 0 ? (
//         <span>
//           <CheckCircleFilled /> Na stanju
//         </span>
//       ) : (
//         <span style={{ color: "red" }}>
//           <CloseCircleFilled style={{ color: "red" }} /> Nema na stanju
//         </span>
//       );
//
//       // if (typeof item.inStock === 'boolean') {
//       //   item.inStock =
//       //     item.inStock === true ? (
//       //       <span>
//       //         <CheckCircleFilled /> Na stanju
//       //       </span>
//       //     ) : (
//       //       <span>
//       //         <CloseCircleFilled /> Nema na stanju
//       //       </span>
//       //     );
//       // }
//       return item;
//     });
//   }
//
//   const handleImportFile = async (event) => {
//     const file = event.target.files[0];
//     let formData = new FormData();
//     formData.append("excelData", file);
//     const response = await Axios.post(`${SERVER_URL}/import-data`, formData, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
//     notification.success({
//       message: response.data.message,
//       placement: 'bottomRight',
//     });
//     setTimeout(() => {
//       window.location.reload();
//     }, 1200);
//   };
//
//   return (
//     <div className='table'>
//       <div className='actions-block'>
//         <Link to='/admin/new-data'>
//           <button className='b2b-primary'>Dodaj proizvod</button>
//         </Link>
//         {/*<Button className='importbutton' disabled={!currentuser.data.role.includes('superAdmin')} style={{ marginLeft: '10px' }} icon={<UploadOutlined />} onClick={() => document.getElementById('input-file').click()} type="button">Uvoz podataka</Button>*/}
//         {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
//       </div>
//
//       <div style={{ textAlign: 'center' }}>
//         {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
//         {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
//           <Table
//             data={tableData}
//             deleteHandler={deleteDataHandler}
//             columnKeys={columnKeys}
//             title='Data'
//             editPath='/admin/edit-data/'
//           />
//         )}
//         {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
//       </div>
//     </div>
//   );
// };
//
// export default Data;

import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Select, Result, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  country: undefined,
  phone: '',
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  console.log('current user form', currentuser)
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  initialValues.legalType = currentuser.data && currentuser.data.role.includes('superAdmin') ? 'PRIVATE' : 'CLIENT';
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [deletePop, setDeletePop] = useState();
  // const [branches, setBranches] = useState([]);
  // const [companyBranch, setCompanyBranch] = useState([]);
  // const [loggedUser, fetchLoggedUser] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [selectedRole, setSelectedRole] = useState(data && data.role && data.role[0]);

  // const getBranches = async () => {
  //   try {
  //     const call = await Axios.post(`${SERVER_URL}/company/branches`, { companies: companyBranch.length ? companyBranch : data.companies[0]._id }, {
  //       withCredentials: false,
  //       headers: { Authorization: `Bearer ${currentuser.data.token}` }
  //     });
  //     setBranches(call.data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  useEffect(() => {
    fetchCompanies(`${SERVER_URL}/companies`, []);
  }, [fetchCompanies]);

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  useEffect(() => {
    if (isNew) {
      form.setFieldsValue(initialValues);
    }
  }, [showModal, form, isNew]);

  if (data && data.companyAdminInfo) {
    user.address = data.companyAdminInfo.address;
    user.city = data.companyAdminInfo.city;
    user.zipCode = data.companyAdminInfo.zipCode;
    user.country = data.companyAdminInfo.country;
    user.companyName = data.companyAdminInfo.companyName;
    user.companyNumber = data.companyAdminInfo.companyNumber;
    user.taxId = data.companyAdminInfo.taxId;
  }

  if (data) {
    user.companies = data?.companies?.map((company) => company._id);
    user.branch = data?.branch?.map((b) => b._id);
  }

  const onFinish = (values) => {
    if (
      user.role.length !== 0 &&
      user.role[0] !== values.role &&
      values.role === 'company' &&
      values.companies.length > 1
    ) {
      values.companies = values.companies[0];
    }
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Greška:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push('/admin/users');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'Novi korisnik' : 'Izmena korisnika'}`} bordered={false}>
            <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
            {profile && (
              <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
                Brisanje korisnika <DeleteOutlined />
              </div>
            )}
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item /*label='Avatar'*/ className='upload-wrapper right' name='avatar'>
                  <UploadBox
                    editHandler={avatarHandler}
                    deleteHandler={deleteAvatarHandler}
                    image={image}
                    index={0}
                    name='avatar'
                  />
                </Form.Item>

                <Form.Item
                  /*label='Email'*/
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete email!',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  /*label='First name'*/
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete ime!',
                    },
                  ]}
                >
                  <Input placeholder='Ime' />
                </Form.Item>

                <Form.Item
                  /*label='Last name'*/
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete prezime!',
                    },
                  ]}
                >
                  <Input placeholder='Prezime' />
                </Form.Item>

                <Form.Item /*label='Phone'*/ name='phone'>
                  <Input placeholder='Telefon' />
                </Form.Item>

                {isNew && (
                  <Form.Item
                    /*label='Password'*/
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Molim Vas da unesete password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Password' />
                  </Form.Item>
                )}

                <Form.Item
                  /*label='Role'*/
                  name='role'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da odaberete role!',
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{ offset: [0, -140] }}
                    placeholder='Role'
                    onChange={setSelectedRole}
                  >
                    <Select.Option value='superAdmin'>Super admin</Select.Option>
                    <Select.Option value='admin'>Admin</Select.Option>
                  </Select>
                </Form.Item>

                {selectedRole && selectedRole === 'companyAdmin' && (
                  <>
                    <Form.Item
                      name='address'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete adresu!',
                        },
                      ]}
                    >
                      <Input placeholder='Adresa' />
                    </Form.Item>

                    <Form.Item
                      name='city'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete grad!',
                        },
                      ]}
                    >
                      <Input placeholder='Grad' />
                    </Form.Item>

                    <Form.Item
                      name='zipCode'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete poštanski kod!',
                        },
                      ]}
                    >
                      <Input placeholder='Poštanski kod' />
                    </Form.Item>

                    <Form.Item
                      name='country'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete zemlju!',
                        },
                      ]}
                    >
                      <Input placeholder='Zemlja' />
                    </Form.Item>

                    <Form.Item
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete naziv kompanije!',
                        },
                      ]}
                    >
                      <Input placeholder='Naziv kompanije' />
                    </Form.Item>

                    <Form.Item
                      name='companyNumber'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete matični broj!',
                        },
                      ]}
                    >
                      <Input placeholder='Matični broj kompanije' />
                    </Form.Item>

                    <Form.Item
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete PIB!',
                        },
                      ]}
                    >
                      <Input placeholder='PIB' />
                    </Form.Item>
                  </>
                )}

                {/*<Form.Item
                  name='userType'
                  rules={[
                    {
                      required: true,
                      message: 'Please select user type!',
                    },
                  ]}
                >
                  <Select placeholder='User type' onChange={(value) => setSelectedType(value)}>
                    {legalType === 'PRIVATE' && <>
                      <Select.Option value='superAdmin'>Super admin</Select.Option>
                      <Select.Option value='user'>User</Select.Option>
                    </>
                    }
                    {legalType === 'CLIENT' && <>
                      {currentuser.data.userType.includes('superAdmin') && <Select.Option value='companyAdmin'>Company admin</Select.Option>}
                      <Select.Option value='companyAdmin'>Company admin</Select.Option>
                      <Select.Option value='company'>Company</Select.Option>
                    </>
                    }
                  </Select>
                  </Form.Item>*/}

                {/*loggedUser.data && loggedUser.data.userType && loggedUser.data.userType.includes('superAdmin') &&
                  <Form.Item
                    name='companyAdmin'
                    rules={[
                      {
                        required: true,
                        message: 'Please select company admin!',
                      },
                    ]}
                  >
                    <Select placeholder='Company admin'>
                      {loggedUser.data.companyAdmins && loggedUser.data.companyAdmins.map((item) => (
                        <Select.Option value={item._id}>{item.firstName} {item.lastName}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                      */}

                {!isNew && (
                  <Form.Item
                    /*label='Status'*/
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Molim Vas da odaberete status!',
                      },
                    ]}
                  >
                    <Select getPopupContainer={(trigger) => trigger.parentNode} dropdownAlign={{ offset: [0, -170] }}>
                      <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
                      <Select.Option value='DELETED'>OBRISAN</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKA_NA_AKTIVACIJU</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                  <div>
                    <button className='b2b-primary' htmlType='submit' style={{ float: 'right' }}>
                      {data ? 'Snimi promene' : 'Dodaj korisnika'}
                    </button>
                    <button
                      className='b2b-secondary'
                      style={{ float: 'right', marginRight: '10px' }}
                      onClick={() => setShowModal(!showModal)}
                    >
                      Poništi
                    </button>
                  </div>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='Korisnik je kreiran i verifikacioni mejl je poslat'
                extra={
                  <Button
                    type='primary'
                    key='console'
                    onClick={() => {
                      setResult(false);
                      window.location.reload();
                    }}
                  >
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Da'
            cancelText='Ne'
          >
            <p>Da li ste sigurni da želite da obrišete</p>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;

import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { notification, Button, Form, Input, DatePicker } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const Profactures = () => {
  const currentuser = useContext(UserContext);
  const [profactures, fetchProfactures] = useAxios('', [], currentuser.data.token, 'get');
  const [invoicesPricesSum, setInvoicesPricesSum] = useState('');
  const [createdAtDate, setCreatedAtDate] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchProfactures(`${SERVER_URL}/orders`, []);
  }, [fetchProfactures]);
  console.log('profactures', profactures);
  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/profactures');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  // function for date formatting in table (dd-mm-yyyy)
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const columnKeys = ['orderNumber', 'companyName', 'createdAt', 'updatedAt', 'status'];
  let tableData = [];
  if (profactures.data && profactures.data.items && profactures.data.items.length > 0) {
    tableData = profactures.data.items.map((item) => {
      if (item.companyName && item.companyName.companyName) {
        item.companyName = item.companyName.companyName;
      }
      if (item.user && item.user.email) {
        // item.buyer = `${item.user.firstName} ${item.user.lastName}`;
        item.buyer = `${item.guestUserData.firstName} ${item.guestUserData.lastName}`;
        item.email = item.user.email;
        item.city = item?.user?.companies[0]?.city;
        item.price = (+item.totalPrice).toFixed(2);
        const createdAtFormat = formatDate(item.createdAt);
        const updatedAtFormat = formatDate(item.updatedAt);
        return { ...item, createdAt: createdAtFormat, updatedAt: updatedAtFormat };
      } else if (item.guestUserData) {
        // item.buyer = `${item.user.firstName} ${item.user.lastName}`;
        item.buyer = `${item.guestUserData.firstName} ${item.guestUserData.lastName}`;
        item.email = item.guestUserData.email;
        item.city = item?.user?.companies[0]?.city;
        item.price = (+item.totalPrice).toFixed(2);
        const createdAtFormat = formatDate(item.createdAt);
        const updatedAtFormat = formatDate(item.updatedAt);
        return { ...item, createdAt: createdAtFormat, updatedAt: updatedAtFormat };
      } else {
        return item;
      }
    });
  }

  const openPdf = async (path) => {
    console.log('open pdf', path);

    try {
      const resPdF = await Axios.get(`${SERVER_URL}/single-order/pdf?path=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  // function that is called when date from datepicker changes
  let priceTotal = 0;
  const onDateChange = (date, dateString) => {
    setInvoicesPricesSum(0);
    setCreatedAtDate(dateString);

    if (profactures.data) {
      for (let i = 0; i < profactures?.data?.items?.length; i++) {
        const dateObj = new Date(profactures.data.items[i].createdAt);
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = String(dateObj.getFullYear());
        const fullDate = `${month}/${year}`;

        if (fullDate === dateString) {
          priceTotal += parseFloat(profactures.data.items[i].totalPrice);
          setInvoicesPricesSum(priceTotal.toLocaleString('sr-RS'));
        }
      }
    }
  };

  // current date is starting date to render sum of prices of invoices
  useEffect(() => {
    if (profactures.data) onDateChange(null, moment().format('MM/YYYY'));
  }, [profactures]);

  return (
    <div className='table'>
      {(currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) && (
        <div className='actions-block'>
          <Link to='/admin/new-profacture'>
            <Button type='primary'>Nova profaktura</Button>
          </Link>
        </div>
      )}
      <div style={{ display: 'flex', gap: '40px', marginLeft: '10px', marginTop: '10px' }}>
        <Form.Item label={`Ukupan iznos na datum ${createdAtDate}:`}>
          <Input disabled value={invoicesPricesSum} suffix='RSD' />
        </Form.Item>
        <Form.Item label='Datum:'>
          <DatePicker
            onChange={onDateChange}
            picker='month'
            style={{ height: '32px' }}
            format='MM/YYYY'
            placeholder='Izaberi datum'
            defaultValue={moment()}
          />
        </Form.Item>
      </div>
      <div style={{ textAlign: 'center' }}>
        {profactures.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!profactures.isLoading && profactures.data && profactures.data.items && profactures.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='Profaktura'
            editPath='/admin/edit-profacture/'
            openPdf={openPdf}
          />
        )}
        {!profactures.isLoading &&
          profactures.data &&
          profactures.data.items &&
          profactures.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Profactures;

import React, { useEffect, useContext, useState } from 'react';
import { Input, Button, Form, Card, Select, DatePicker } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
const { Option } = Select;

// const configJodit = {
//   readonly: false, // all options from https://xdsoft.net/jodit/doc/
//   toolbarAdaptive: false,
//   minHeight: '300',
//   buttons: [
//     'source',
//     '|',
//     'bold',
//     'strikethrough',
//     'underline',
//     'italic',
//     'eraser',
//     '|',
//     'superscript',
//     'subscript',
//     '|',
//     'ul',
//     'ol',
//     '|',
//     'outdent',
//     'indent',
//     '|',
//     // 'font',
//     'fontsize',
//     '\n',
//     'brush',
//     'paragraph',
//     'link',
//     'align',
//     // '|',
//     'undo',
//     'redo',
//     'selectall',
//     'cut',
//     'copy',
//     'paste',
//     'copyformat',
//     '|',
//     'hr',
//     'symbol',
//     'fullsize',
//     'print',
//     'preview',
//     'find',
//   ],
// };

const formInit = {
  obligationText: undefined,
  obligationDate: undefined,
  status: undefined,
};

const ObligationForm = ({ isNew, data, onSubmit, language, token }) => {
  const currentuser = useContext(UserContext);

  const [form] = Form.useForm();
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');

  let initialValues = { ...formInit, ...data };
  const [obligationDate, setObligationDate] = useState(initialValues.obligationDate);

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else {
      let filter = { client: currentuser.data.id };
      if (companies.data.length === 0) {
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
      if (companies && companies.data && companies.data.items && companies.data.items.length === 1) {
        filter = { $or: [{ _id: companies.data.items[0]._id }, { mainCompany: companies.data.items[0]._id }] };
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
    }
  }, [fetchCompanies, currentuser.data.id, currentuser.data.role, companies]);

  const onFinish = async (values, isNew) => {
    values.obligationDate = obligationDate;
    onSubmit(values, isNew);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='Izmena obligacije' bordered={false}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='vertical'
            form={form}
          >
            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Obligacija'
              name='obligationText'
            >
              <Input placeholder='Naziv obligacije' />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Status obligacije'
              name='status'
            >
              <Select>
                <Option value='OTVORENA'>OTVORENA</Option>
                <Option value='ZATVORENA'>ZATVORENA</Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Datum obligacije'
            >
              <DatePicker
                defaultValue={moment(initialValues.obligationDate, 'MM-DD-YYYY')}
                format='DD-MM-YYYY'
                onChange={(e) => setObligationDate(e.format('L'))}
                allowClear={false}
              />
            </Form.Item>

            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                Izmeni obligaciju
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ObligationForm;

import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import ViewData from '../pages/data/ViewData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Profactures from '../pages/profactures/Profactures';
import EditProfacture from '../pages/profactures/EditProfactures';
import NewProfacture from '../pages/profactures/NewProfacture';
import Companies from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import ViewCompany from '../pages/company/ViewCompany';
import ContactsCompany from '../pages/company/ContactsCompany';
import SingleCompany from '../pages/company/SingleCompany';
import AddCompanyBranch from '../pages/company/AddCompanyBranch';
import Obligations from '../pages/obligations/Obligations';
import EditObligation from '../pages/obligations/EditObligation';

const routes = [
  {
    label: 'Korisnici',
    showInMenu: ['superAdmin'],
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Klijenti',
    showInMenu: ['superAdmin', 'admin'],
    children: [
      {
        label: 'Svi klijenti',
        path: '/admin/companies',
        component: Companies,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj klijenta',
        path: '/admin/new-company',
        component: EditCompany,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni klijenta',
        path: '/admin/edit-company/:id',
        component: EditCompany,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Kontakti klijenta',
        path: '/admin/company-contacts/:id',
        component: ContactsCompany,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Pojedinačni klijent',
        path: '/admin/single-company/:id',
        component: SingleCompany,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Vidi kompaniju',
        path: '/admin/view-company/:id',
        component: ViewCompany,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Dodaj ogranak',
        path: '/admin/new-branch',
        component: AddCompanyBranch,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/admin/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Proizvodi',
    showInMenu: ['superAdmin', 'admin'],
    children: [
      {
        label: 'Svi proizvodi',
        path: '/admin/data',
        component: Data,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvod',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Data',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'View Data',
        path: '/admin/view-data/:dataId',
        component: ViewData,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Add/Edit Category',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Obligacije',
    showInMenu: ['superAdmin', 'admin'],
    children: [
      {
        label: 'Sve obligacije',
        path: '/admin/obligation',
        component: Obligations,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      // {
      //   label: 'Nova obligacija',
      //   path: '/admin/new-obligation',
      //   component: EditObligation,
      //   allowed: ['superAdmin', 'admin'],
      //   showInMenu: true,
      // },
      {
        label: 'Izmena obligacije',
        path: '/admin/edit-obligation/:id',
        component: EditObligation,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Profakture',
    showInMenu: ['superAdmin', 'admin'],
    children: [
      {
        label: 'Sve profakture',
        path: '/admin/profactures',
        component: Profactures,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Nova profaktura',
        path: '/admin/new-profacture',
        component: NewProfacture,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Edit profakture',
        path: '/admin/edit-profacture/:profactureId',
        component: EditProfacture,
        allowed: ['superAdmin', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Fakture',
    showInMenu: ['superAdmin', 'admin'],
    children: [
      {
        label: 'Sve fakture',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['superAdmin', 'admin'],
        showInMenu: true,
      },
    ],
  },
];

export default routes;
